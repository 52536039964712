import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Times from "../components/openingtimes"
// import XmasTimes from "../components/xmastimes"

class OpeningTimes extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Opening times at Capernwray" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="page-title my-4">Opening Times</h1>
              <Times season="summer" />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default OpeningTimes

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
